const getSteps = (size) => {
  return [
    "interpolate",
    ["linear"],
    ["zoom"],
    1,
    0.2 * size,
    2,
    0.2 * size,
    3,
    size * 0.8,
    4,
    size,
    7,
    size,
    15,
    size * 2,
  ];
};

var stops = 6;
var logoStops = 9;
var busStops = 25;

var trainColorSet = [
  "#f7ed79",
  "#d31d2a",
  "#58a348",
  "#fefcfd",
  "#d31d2a",
  "#58a348",
  "#fefcfd",
];

var fColorSet = [
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79" /*
  "#55cbcd",
  "#2e62c5",
  "#55cbcd",
  "#2e62c5",
  "#55cbcd",
  "#2e62c5",
  "#55cbcd",
  "#55cbcd",*/,
];

var State_Borders_PointsColorSet = [
  /*"#e41587",
  "#8330ff",
  "#e41587",
  "#8330ff",
  "#e41587",
  "#8330ff",
  "#e41587",
  "#8330ff",
  "#e41587",
  "#8330ff",
*/
  "white",
  "#f7ed79",
  "white",
  "#f7ed79",
  "white",
  "#f7ed79",
  "white",
  "#f7ed79",
  "white",
];

var logoColorSet = [
  "#f7ed79",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
  "#f7ed79",
  "#f3c71f",
];

var letterMap = {
  L: 0,
  O: 1,
  N: 2,
  E: 3,
  M: 4,
  A: 5,
  P: 6,
  W: 7,
  R: 8,
};

var buildStops = function (count, stops, colorSet) {
  var theStops = parseInt(stops);
  stops = [];
  var i = 0;
  for (i = 0; i < theStops; i++) {
    stops.push([i, colorSet[(i + count) % theStops]]);
  }

  return stops;
};

var addGroupId = function (geojson, number, type) {
  var count = 0;
  geojson.features.forEach(function (point) {
    var id = 0;

    count++;
    if (count) {
      //do nothing!!;
    }

    if (type && type == "bus") {
      //id = count % number;

      id = Math.floor(Math.random() * (number + 1));
      point.properties.groupid = id;
    } else if (type && type == "train") {
      //id = count % number;

      id = Math.floor(Math.random() * number);
      point.properties.groupid = id;
    } else if (point.properties && point.properties.order) {
      id = point.properties.order % number;
      point.properties.groupid = id;
    } else if (point.properties && point.properties.Letter) {
      var letter = point.properties.Letter;

      //logoStops = letterIndex;

      //id = point.properties.id % number;
      point.properties.groupid = letterMap[letter] ? letterMap[letter] : 0;
      point.properties.opacityId = point.properties.id % 2;
    } else if (point.properties && point.properties.id) {
      id = point.properties.id % number;
      point.properties.groupid = id;
    }
  });

  return geojson;
};
const roundNumber = (number) => Math.round(number * 100000) / 100000;
/*
import points from "./../../dataset/2022/Done/statepoints2022_out.json";





const roundNumber = (number) => Math.round(number * 100000) / 100000;

var value = points.features.map((row) => {
  let coordinates = row.geometry.coordinates;
  return {
    x: roundNumber(coordinates[0]),
    y: roundNumber(coordinates[1]),
  };
});
console.log(value);

*/

//import Rail_Roads_Points from "./../../dataset/FerryUnique.json";
//import Populated_Places_Points from "./../../dataset/TrainUniqe.json";

//clean;
import Logo_Lines from "./../../dataset/2022/logo/logolines2022_out.json";
import Logo_Points from "./../../dataset/2022/logo/logopoints2022_out.json";

import Random_Points from "./../../dataset/2022/data/randomPoints.json";
import Rail_Roads_Lines from "./../../dataset/2022/data/railroads2022_out.json";
import Populated_Places_Points_MAJOR from "./../../dataset/2022/data/MAJOR_populatedplaces2022_out.json";

import Populated_Places_Points_minor from "./../../dataset/2022/data/MINOR_populatedplaces2022_out.json";

import State_Borders_Points from "./../../dataset/2022/data/statepoints2022_out.json";

import Rail_Roads_Points from "./../../dataset/2022/data/railroadspoints2022_out.json";

for (let index = 0; index < 15; index++) {
  Populated_Places_Points_MAJOR.features.push({
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [
        roundNumber(Math.random() / 10),
        roundNumber(Math.random() / 10),
      ],
    },
  });
}

/*
import points from "./../../dataset/2022/data/MAJOR_populatedplaces2022_out.json";


const value = points.features.map((row) => {
  let coordinates = row.geometry.coordinates;

  //x: ,
  //y: roundNumber(coordinates[1]),
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [roundNumber(coordinates[0]), roundNumber(coordinates[1])],
    },
  };
});

console.log({
  type: "FeatureCollection",
  //"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: value,
});


*/
/*

*/

/*

import line  from "./../../dataset/2022/logo/logolines2022_out.json";

line.features.forEach((row) => {
  row.properties = {};
  row.geometry.coordinates.forEach((set, i) => {
    set.forEach((point, j) => {
      row.geometry.coordinates[i][j] = [
        roundNumber(point[0]),
        roundNumber(point[1]),
      ];
    });
  });
});

console.log(line);









import points from "./../../dataset/2022/data/MINOR_populatedplaces2022_out.json";

points.features.forEach(row => {
  row.properties = {};
  row.geometry.coordinates.forEach((point, index) => {
    row.geometry.coordinates[index] = [
      roundNumber(point[0]),
      roundNumber(point[1])
    ];
  });
});

console.log(points);

*/
/*
import points from "./../../dataset/2022/logo/logopoints2022_out.json";

points.features.forEach((point, index) => {
  points.features[index].geometry.coordinates = [
    roundNumber(point.geometry.coordinates[0]),
    roundNumber(point.geometry.coordinates[1]),
  ];
});

console.log(points);
*/
var Random_Order_Points = {
  type: "FeatureCollection",
  //"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [],
};

var threshold = 0.3;
Random_Points.forEach(function (point) {
  if (Math.random() < threshold) {
    Random_Order_Points.features.push({
      type: "Feature",
      properties: {},
      geometry: { type: "Point", coordinates: [point.x, point.y] },
    });
  }
});

addGroupId(Populated_Places_Points_MAJOR, stops, "train");

addGroupId(Populated_Places_Points_minor, stops, "train");

addGroupId(Rail_Roads_Points, stops, "train");
addGroupId(State_Borders_Points, stops, "train");
addGroupId(Logo_Points, 10);
addGroupId(Random_Order_Points, busStops, "bus");
var parentThisAct = null;
export default new (class {
  async load(map, parentThis) {
    parentThisAct = parentThis;

    map.addLayer({
      id: "Rail_Roads_Points",
      type: "circle",
      source: {
        type: "geojson",
        data: Rail_Roads_Points,
      },
      layout: {},
      paint: {
        "circle-radius": getSteps(4),
        "circle-opacity": 1,
        "circle-color": "transparent",
        "circle-blur": 1,
      },
    });

    map.addLayer({
      id: "State_Borders_Points",
      type: "circle",
      source: {
        type: "geojson",
        data: State_Borders_Points,
      },
      layout: {},
      paint: {
        "circle-radius": getSteps(2.5),
        "circle-opacity": 1,
        "circle-color": "transparent",
        "circle-blur": 1,
      },
    });

    map.addLayer({
      id: "Random_Order_Points",
      minzoom: 2,
      //"maxzoom": 12,
      type: "circle",
      source: {
        type: "geojson",
        data: Random_Order_Points,
      },
      layout: {},
      paint: {
        "circle-radius": 0,
        "circle-color": "white",
        "circle-opacity": 0,
        "circle-blur": 1,
      },
    });

    map.addLayer({
      id: "Rail_Roads_Lines",
      type: "line",
      source: {
        type: "geojson",
        data: Rail_Roads_Lines,
      },
      layout: {},
      paint: {
        "line-color": "#fbff05",
        "line-opacity": 0.7,
        "line-width": 0.5,
      },
    });

    map.addLayer({
      id: "Populated_Places_Points_Order_minor_color",
      type: "circle",
      source: {
        type: "geojson",
        data: Populated_Places_Points_minor,
      },
      layout: {},
      paint: {
        "circle-radius": getSteps(6),
        //"circle-color": "#007cbf",
        "circle-opacity": 1,
        "circle-color": "transparent",
        "circle-blur": 1,
      },
    });

    map.addLayer({
      id: "Populated_Places_Points_Order_color",
      type: "circle",
      source: {
        type: "geojson",
        data: Populated_Places_Points_MAJOR,
      },
      layout: {},
      paint: {
        "circle-radius": getSteps(12),
        //"circle-color": "#007cbf",
        "circle-opacity": 1,
        "circle-color": "transparent",
        "circle-blur": 1,
      },
    });

    map.addLayer({
      id: "Logo_Lines",
      type: "line",
      source: {
        type: "geojson",
        data: Logo_Lines,
      },
      layout: {},
      paint: {
        "line-color": "#f3c71f",
        "line-opacity": 0.7,
        "line-width": 2,
      },
    });

    map.addLayer({
      id: "Logo_Points",

      type: "circle",
      source: {
        type: "geojson",
        data: Logo_Points,
      },
      layout: {},
      paint: {
        "circle-blur": 1,
        "circle-radius": getSteps(3),
        "circle-color": "transparent",
        "circle-opacity": 0.7,
      },
    });

    var currentIndex = 0;
    setInterval(function () {
      currentIndex++;
      map.setPaintProperty(
        "Populated_Places_Points_Order_color",
        "circle-color",
        {
          property: "groupid",
          stops: buildStops(currentIndex, stops, trainColorSet),
        }
      );
      map.setPaintProperty(
        "Populated_Places_Points_Order_minor_color",
        "circle-color",
        {
          property: "groupid",
          stops: buildStops(currentIndex, stops, trainColorSet),
        }
      );

      map.setPaintProperty("State_Borders_Points", "circle-color", {
        property: "groupid",
        stops: buildStops(currentIndex, stops, State_Borders_PointsColorSet),
      });

      map.setPaintProperty("Rail_Roads_Points", "circle-color", {
        property: "groupid",
        stops: buildStops(currentIndex, 3, fColorSet),
      });

      var theLogoStops = buildStops(currentIndex, logoStops, logoColorSet);

      theLogoStops[7] = [7, "white"];
      theLogoStops[8] = [8, "red"];

      map.setPaintProperty("Logo_Points", "circle-color", {
        property: "groupid",
        stops: theLogoStops,
      });

      var opacityStops = [
        [0, (4 + (currentIndex % 2)) * parentThisAct.zoomScale],
        [1, (4 + ((currentIndex + 1) % 2)) * parentThisAct.zoomScale],
      ];

      //console.log(parentThisAct.zoomScale, opacityStops);

      map.setPaintProperty("Logo_Points", "circle-radius", {
        property: "opacityId",
        stops: opacityStops,
      });
    }, 1300);

    var currentIndex2 = 10;

    var isFirst = true;
    setInterval(function () {
      currentIndex2++;
      var busTopArray = [];
      for (var j = 0; j < busStops; j++) {
        var size = 1 * parentThisAct.zoomScale;
        var bustopLightIndex = currentIndex2 % busStops;
        if (j == bustopLightIndex) {
          size = 3 * parentThisAct.zoomScale;
        }
        busTopArray.push([j, size]);
      }
      map.setPaintProperty("Random_Order_Points", "circle-radius", {
        property: "groupid",
        stops: busTopArray,
      });
      if (isFirst) {
        setTimeout(function () {
          map.setPaintProperty(
            "Random_Order_Points",
            "circle-opacity",
            getSteps(1)
          );
        }, 700);
      }
      isFirst = false;
    }, 3000);
  }
})();
