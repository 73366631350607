<template>
  <div class="top nav">
    <div class="mobileHeader">
      <div style="float: left; width: 40%" class="logo">
        <a href="https://onemap.com.au" target="_blank">
          <img
            style="max-height: 35px; max-widtht: 100%; margin: 8px 0 0 16px"
            src="./../assets/onemap-site-header-logo-retina-light-2.png"
          />
        </a>
      </div>
      <div style="float: right; width: 50%; color: white"></div>
    </div>

    <div class="mainHeader">
      <div class="" style="float: left">
        <a href="https://onemap.com.au" target="_blank">
          <img
            style="max-height: 40px; margin: 8px 0 0 16px"
            src="./../assets/PoweredByOneMap.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "topAct",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 500;
}

.right {
  position: fixed;
  top: 0;
  right: 0;
  width: 56px;
  height: 100%;
  z-index: 500;
  margin-top: 56px;
}

.titleBox {
  color: white;
  margin-left: 100px;
}

.iframeMode .titleBox {
  display: none;
}

.mobileHeader {
  display: none;
}

.mainHeader {
  display: block;
}

@media (max-width: 800px) {
  .mobileHeader {
    display: block;
  }

  .mainHeader {
    display: none;
  }

  .vue-slider-piecewise-label {
    display: none !important;
  }
}
</style>
