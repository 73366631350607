<template>
  <div class="mapWrapper">
    <div id="themap"></div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import lightService from "./lightService.js";
var mapboxgl;
export default {
  name: "XmasMap",

  data: () => ({
    map: null,
    zoomScale: 1,
    zoomScaleSets: {
      1: 0.2,
      1.5: 0.3,
      2: 0.5,
      2.5: 0.5,
      3: 0.7,
      3.5: 0.9,
      4: 1,
    },
  }),
  mounted() {
    mapboxgl = window.mapboxgl;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWFyb25zaW1zIiwiYSI6ImNqN2Z0NjVkYjExNHkyd28yNnc5cXhjNWgifQ.cgZZrzDKR2B8wX9TBXJ_kw";

    this.map = new mapboxgl.Map({
      container: "themap",
      //projection: "globe",
      style: "mapbox://styles/mapbox/dark-v11",
      center: [135, -40], //[0, 0], //
      zoom: 2,
      minZoom: 0,
      maxZoom: 13,
      bearing: 0, // -70,
      pitch: 0, //-60 // pitch in degrees
    });

    this.map.on("load", () => {
      lightService.load(this.map, this);

      this.map.fitBounds(
        [
          [116, -43], // southwestern corner of the bounds
          [151, -9], // northeastern corner of the bounds
        ],
        { pitch: 0, bearing: 0 }
      );
    });

    this.map.on("zoomend", () => {
      let zoom = Math.ceil(this.map.getZoom() * 2) / 2;
      this.zoomScale = this.zoomScaleSets[zoom] ? this.zoomScaleSets[zoom] : 1;
      //console.log(zoom, this.zoomScale);

      //if (this.updateLogStop) this.updateLogStops();
      //if (this.updateBusStop) this.updateBusStop();

      if (this.map.getLayer("Random_Order_Points")) {
        this.map.setPaintProperty(
          "Random_Order_Points",
          "circle-radius",
          1 * this.zoomScale
        );
      }
    });
  },
};
</script>

<style>
html,
body,
div#xmaxMap {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: black;
}

.mapWrapper,
#xmaxMap {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100vw !important;
  border: none;
}

#themap {
  height: 100%;
  width: 100vw;
}

#mapContainer {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
}
</style>
