<template>
  <div class="xmaxMap">
    <Map />
    <top></top>
    <bottom ref="bottom"></bottom>
  </div>
</template>

<script>
import Map from "./components/map/Map.vue";

import top from "./components/top";
import bottom from "./components/bottom";

export default {
  name: "xmaxMap",
  components: { Map, top, bottom },
  data: () => ({}),
};
</script>
<style>
html,
body,
div,
span,
applet,
object,
iframe,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  font-family: "Lato", sans-serif;
}
</style>
