<template>
  <div class="bottom nav">
    <div class="mobileHeader">
      <div style="float: right; width: 100%; color: white">
        <div style="margin: 8px 16px 0 0; text-align: right; font-size: 20px">
          Season's Greetings from the <br />OneMap Team
        </div>
      </div>
    </div>

    <div class="mainHeader">
      <div style="text-align: center" class="titleBox">
        <div
          style="
            color: white;
            margin: 8px 16px 0 0;
            font-size: 20px;
            font-size: 35px;
          "
        >
          Season's Greetings from the OneMap Team
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "bottomAct",
  methods: {
    doNothing() {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 500;
}

.right {
  position: fixed;
  top: 0;
  right: 0;
  width: 56px;
  height: 100%;
  z-index: 500;
  margin-top: 56px;
}

.titleBox {
  color: white;
  margin-left: 100px;
}

.iframeMode .titleBox {
  display: none;
}

.mobileHeader {
  display: none;
}

.mainHeader {
  display: block;
}

@media (max-width: 800px) {
  .mobileHeader {
    display: block;
  }

  .mainHeader {
    display: none;
  }

  .vue-slider-piecewise-label {
    display: none !important;
  }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}
</style>
